import { Box, Grid, Skeleton } from '@achieve/sunbeam'
import styles from './VideoPlayer.module.scss'

import React, { useContext, useReducer, useEffect, useState } from 'react'
import useViewportSize from 'hooks/useViewportSize'
import { videoMap } from 'constants/videoMappings'
import { iOSSafariMobile, TYPES_SOURCES_VIDEOS } from 'utils/shared'
import useIsSsr from 'hooks/useIsSsr'
import { BREAKPOINTS, useViewportSmallerThan } from 'utils/mui'
import dynamic from 'next/dynamic'
const Vimeo = dynamic(() => import('@u-wave/react-vimeo'))
import YouTube from 'react-youtube'

import { AnalyticsContext } from 'providers/AnalyticsProvider'
import { updateTrackWithListedEvent } from 'utils/analytics/trackingLibrary/updateTrackEventWithListedEvent'
import { useEventScope } from 'hooks/useEventScope'
import { EVENT_LIST } from 'utils/analytics/sync-event-list'

const reducerFunction = (state, action) => {
  switch (action.type) {
    case 'initVideo':
      return { ...state, ...action.payload }
    case 'calculatedClient':
      return { ...state, ...action.payload }
    default:
      return state
  }
}

function VideoPlayer({
  content = {},
  maxWidth = false,
  frameClassName,
  frameEmbed = false,
  aspectRatio,
  trackOnPlay = {},
}) {
  const isSsr = useIsSsr()
  const [isSafariIOS, setIsSafariIOS] = useState(false)

  const { source, autoplay, altText, openFullScreen } = content

  const { breakpointLetterSize, calculatedClient, screenWidth } = useViewportSize()

  const isMobile = useViewportSmallerThan(BREAKPOINTS.lg)
  const [heightAspectRadio, setHeightAspectRadio] = useState(0)

  const specificity = useEventScope()
  const { dispatch: analyticsDispatch } = useContext(AnalyticsContext)

  const [state, dispatch] = useReducer(reducerFunction, {
    typeVideo: null,
    showVideo: false,
    dimension: {
      height: videoMap[breakpointLetterSize].height,
      width: videoMap[breakpointLetterSize].width,
    },
  })
  // use 40 to fix videoplayer padding on about us page
  const mobileWidth = maxWidth ? screenWidth : screenWidth - 40

  useEffect(() => {
    if (!isSsr) {
      // check If current browser is a safari browser IOS
      setIsSafariIOS(iOSSafariMobile(navigator.userAgent))
    }
  }, [isSsr])

  useEffect(() => {
    setHeightAspectRadio(isMobile ? mobileWidth / aspectRatio : state.dimension.width / aspectRatio)
  }, [isMobile, mobileWidth, state.dimension.width, aspectRatio])

  useEffect(() => {
    if (calculatedClient) {
      dispatch({
        type: 'calculatedClient',
        payload: {
          dimension: {
            height: videoMap[breakpointLetterSize].height,
            width: videoMap[breakpointLetterSize].width,
          },
        },
      })
    }
  }, [calculatedClient, breakpointLetterSize])

  useEffect(() => {
    if (source) {
      setTimeout(() => {
        dispatch({
          type: 'initVideo',
          payload: {
            typeVideo:
              String(source).toLowerCase().indexOf(TYPES_SOURCES_VIDEOS.youtube) > -1
                ? TYPES_SOURCES_VIDEOS.youtube
                : TYPES_SOURCES_VIDEOS.vimeo,
            showVideo: true,
          },
        })
      }, 2000)
    }
  }, [source])

  const handleEvent = (eventname) => {
    switch (eventname) {
      case 'playing':
        return analyticsDispatch({
          type: 'ADD_EVENT_TO_QUEUE',
          payload: updateTrackWithListedEvent(
            {
              ...trackOnPlay,
              list_name: EVENT_LIST.VIDEO_PLAYER_PLAY_INITIATED,
            },
            specificity
          ),
        })
      case 'pause':
        return analyticsDispatch({
          type: 'ADD_EVENT_TO_QUEUE',
          payload: updateTrackWithListedEvent(
            {
              ...trackOnPlay,
              list_name: EVENT_LIST.VIDEO_PLAYER_PAUSE,
            },
            specificity
          ),
        })
      case 'end':
        return analyticsDispatch({
          type: 'ADD_EVENT_TO_QUEUE',
          payload: updateTrackWithListedEvent(
            {
              ...trackOnPlay,
              list_name: EVENT_LIST.VIDEO_PLAYER_END,
            },
            specificity
          ),
        })
      default:
        return
    }
  }

  useEffect(() => {
    window.addEventListener('message', handleEvent)

    return () => {
      window.removeEventListener('message', handleEvent)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const opts = {
    height: !frameEmbed && state.dimension.height,
    width: !frameEmbed && (isMobile ? mobileWidth : state.dimension.width),
    playerVars: {
      playsinline: 0,
      autoplay: autoplay ? 1 : 0,
      mute: isMobile ? 1 : 0,
    },
  }

  const getVideoId = (src) => {
    return src.split('?')[0].split('/').pop()
  }

  return (
    <>
      <Grid
        data-testid="iframe-container"
        style={
          frameClassName
            ? {}
            : {
                height:
                  state.typeVideo === TYPES_SOURCES_VIDEOS.youtube
                    ? 'fit-content'
                    : aspectRatio
                    ? heightAspectRadio
                    : state.dimension.height,
                width: isMobile ? mobileWidth : state.dimension.width,
                margin: 'auto',
              }
        }
        className={frameClassName}
        container
      >
        <div
          data-testid="iframe-container"
          className={frameClassName ? styles['iframe-wrapper-external'] : styles['iframe-wrapper']}
        >
          {!state.showVideo && (
            <Skeleton
              variant="rectangular"
              width={isMobile ? mobileWidth : state.dimension.width}
              height={aspectRatio ? heightAspectRadio : state.dimension.height}
            />
          )}
          {state.showVideo && state.typeVideo === TYPES_SOURCES_VIDEOS.vimeo && (
            <Box className={frameEmbed && styles['video-player-embed']}>
              <Vimeo
                data-testid="vimeo-iframe"
                title={altText}
                video={source}
                height={!frameEmbed && (aspectRatio ? heightAspectRadio : state.dimension.height)}
                width={!frameEmbed && (isMobile ? mobileWidth : state.dimension.width)}
                autopause
                autoplay={autoplay}
                playsInline={false}
                onReady={(player) => {
                  try {
                    // Only touch devices has TouchEvent
                    document.createEvent('TouchEvent')
                    if (openFullScreen && !isSafariIOS) {
                      setTimeout(() => {
                        player.requestFullscreen()
                      }, 500)
                    }
                  } catch (e) {
                    return false
                  }
                }}
                onPlay={(e) => {
                  e.requestFullscreen()
                  window.parent.postMessage('onPlay', '*')
                }}
                onPause={() => {
                  window.parent.postMessage('onPause', '*')
                }}
                onEnd={() => {
                  window.parent.postMessage('onEnd', '*')
                }}
              />
            </Box>
          )}
          {state.showVideo && state.typeVideo === TYPES_SOURCES_VIDEOS.youtube && (
            <Box className={frameEmbed && styles['video-player-embed']}>
              <YouTube
                videoId={getVideoId(source)}
                opts={opts}
                style={{ border: 0 }}
                title={altText}
                onPlay={() => {
                  handleEvent('playing')
                }}
                onPause={() => {
                  handleEvent('pause')
                }}
                onEnd={() => {
                  handleEvent('end')
                }}
              />
            </Box>
          )}
        </div>
      </Grid>
    </>
  )
}
export { VideoPlayer }
