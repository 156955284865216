import { MediaImage, Typography, LinkButton } from 'components/Contentful'
import { Grid } from '@achieve/sunbeam'
import { useViewportSmallerThan, BREAKPOINTS } from 'utils/mui'
import styles from './CardCTA.module.scss'
import { useCurrentUrl } from 'hooks/useCurrentUrl'
import { isExternal } from 'utils/conversions'

export const CardCTA = ({ content, variant, trackingName }) => {
  let { link, title, subtitle, image } = content

  const isMobile = useViewportSmallerThan(BREAKPOINTS.md)
  const currentURL = useCurrentUrl()

  return (
    <div className={styles[`outer-wrapper-${variant ? variant : 'main'}`]}>
      <Grid className={styles['container']}>
        <Grid className={styles['inner-container']}>
          {image && (
            <div className={styles['image-container']}>
              <MediaImage
                className={styles['image']}
                alt={image.fields.mediaAltText}
                content={image.fields.mediaContent}
                data-testid="cta-card-main-image"
                objectFit="cover"
              />
            </div>
          )}
          <Grid className={styles['text-container']}>
            <Typography
              className={styles['cta-title']}
              fontWeight={variant ? 'medium' : 'bold'}
              variant={variant ? 'displayS20' : isMobile ? 'displayS20' : 'displayS30'}
              content={title}
            ></Typography>
            {subtitle && (
              <Typography
                className={styles['cta-subtitle']}
                variant={variant ? 'displayXS30' : 'displayS30'}
                content={subtitle}
              ></Typography>
            )}
            <LinkButton
              track={{
                list_name: 'CTA CARD',
                click_id: link?.fields?.linkText?.content[0]?.content[0]?.value,
                click_text: `Achieve-Web | ${link?.fields?.linkText?.content[0]?.content[0]?.value}`,
                nav_link_section: `CTA Card - ${trackingName}`,
                click_type:
                  isExternal(link?.fields?.linkHref, currentURL) ||
                  link?.fields?.linkHref?.includes('get-started')
                    ? 'internal_campaign_click'
                    : 'page_nav_click',
              }}
              className={styles['cta-button']}
              content={link}
              data-testid="cta-card-button"
              {...(variant && {
                variant: variant ? (variant == 'widget' ? 'outlined' : 'primary') : null,
                color: variant ? (variant == 'widget' ? 'primary' : 'secondary') : null,
              })}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
