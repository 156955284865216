import SectionContainer from './SectionContainer'
import { MediaImage } from 'components/Contentful'
import styles from './Section.module.scss'
import classNames from 'classnames'
import { Image } from 'components/Image'

const bgBlue = 'blue'
const bgDefault = 'bgDefault'
const bgPrimaryHighlightBlue = 'bgPrimaryHighlightBlue'
const bgShadowPrimaryHighlightBlue = 'bgShadowPrimaryHighlightBlue'
const bgShadowPrimaryHighlightWhiteBlue = 'bgShadowPrimaryHighlightWhiteBlue'
const blueGradient1 = 'blueGradient1'
const neutralGrey8 = 'neutralGrey8'
const purpleBlueGradient1 = 'purpleBlueGradient1'
const white = 'white'
const achieveHighlightBlue = 'achieveHighlightBlue'

export const SECTION_VARIANTS = {
  bgBlue,
  bgPrimaryHighlightBlue,
  bgDefault,
  bgShadowPrimaryHighlightBlue,
  bgShadowPrimaryHighlightWhiteBlue,
  blueGradient1,
  purpleBlueGradient1,
  neutralGrey8,
  white,
  achieveHighlightBlue,
}

function Section({
  children,
  defaultPadY = false,
  backgroundColor,
  backgroundImageAlt = '',
  backgroundImageContent,
  backgroundPriority = false,
  backgroundObjectPosition = 'top',
  childContainerClassName,
  className,
  id,
  showPattern = false,
  contain = true,
  variant = bgDefault,
  ...restProps
}) {
  const contents = contain ? (
    <SectionContainer className={childContainerClassName}>{children}</SectionContainer>
  ) : (
    children
  )

  return (
    <section
      id={id}
      style={{ backgroundColor }}
      className={classNames(styles['achieve-section'], className)}
      data-variant={variant}
      data-default-pad-y={defaultPadY}
      {...restProps}
    >
      {showPattern && (
        <Image
          src="/a-pattern.svg"
          alt="Achieve pattern"
          layout="fill"
          objectFit="cover"
          objectPosition={backgroundObjectPosition}
          className={styles['achieve-pattern']}
        />
      )}
      {/* Only display the a full background image when present and background pattern is disabled */}
      {!showPattern && backgroundImageContent && (
        <MediaImage
          content={backgroundImageContent}
          layout="fill"
          objectFit="cover"
          alt={backgroundImageAlt}
          objectPosition={backgroundObjectPosition}
          priority={backgroundPriority}
        />
      )}
      {contents}
    </section>
  )
}

export default Section
