import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { Link as SunbeamLink } from '@achieve/sunbeam'
import { get as _get } from 'lodash-es'
import styles from './TypographyLongForm.module.scss'
import {
  TypographyStringType,
  createRenderNodeDefault,
  handleContentToMapper,
  optionsDefault,
  selectOverride,
} from 'components/Contentful/Typography/Typography'
import { CardCTA } from 'components/Cards/CardCTA'
import { SuperScript } from 'components/Contentful'
import { VideoDialog } from 'components/VideoDialog'
import { VideoPlayer } from 'components/VideoPlayer'
import { rewriteAssetUrl } from 'utils/conversions'
import { UncrawlableLinkTypography } from 'components/Contentful/Typography'
import { removeSpecialCharacters } from 'utils/shared'

const RICH_TEXT = {
  [BLOCKS.PARAGRAPH]: { variant: 'bodyS30', component: 'p' },
  [BLOCKS.HEADING_1]: { variant: 'displayM10', component: 'h1' },
  [BLOCKS.HEADING_2]: {
    variant: 'displayS20',
    component: 'h2',
    fontWeight: 'medium',
  },
  [BLOCKS.HEADING_3]: { variant: 'displayS10', component: 'h3', fontWeight: 'medium' },
  [BLOCKS.HEADING_4]: { variant: 'displayXS30', component: 'h4', fontWeight: 'medium' },
  [BLOCKS.HEADING_5]: { variant: 'displayXS20', component: 'h5' },
  [BLOCKS.HEADING_6]: { variant: 'displayXS10', component: 'h6' },
  [BLOCKS.OL_LIST]: { component: 'ol' },
  [BLOCKS.UL_LIST]: { component: 'ul' },
  [BLOCKS.LIST_ITEM]: { component: 'li' }, //sx properties apply to single child element
  [BLOCKS.HR]: {},
  [BLOCKS.EMBEDDED_ASSET]: {},
  [BLOCKS.TABLE]: { variant: 'bodyS40', component: 'table' },
  [BLOCKS.TABLE_ROW]: { variant: 'bodyS40', component: 'tr' },
  [BLOCKS.TABLE_CELL]: { variant: 'bodyS40', component: 'td' },
  [BLOCKS.TABLE_HEADER_CELL]: { variant: 'bodyS40', component: 'th' },
  [INLINES.HYPERLINK]: { component: 'a' }, // inlines should inherit the font variant
  [INLINES.ASSET_HYPERLINK]: { component: 'a' }, // inlines should inherit the font variant
  // Currently unimplemented in Sunbeam
  //[MARKS.CODE]: {component: 'code'},
  //[BLOCKS.QUOTE]:{},
}

/**
 * Render Contentful TypographyLongForm components from Contentful Rich Text
 * To build the variantOverride object you will need to import import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'
 * @param {Object} content
 * @param {Object} variantOverride object of rich-text-types as keys and Sunbeam Variants as the value
 * @param {Object} styleOverride object of rich-text-types as keys and a MUI "sx" object as the value
 * @returns
 */
function TypographyLongForm({
  content: typographyContent = '',
  variantOverride,
  styleOverride,
  ...props
}) {
  if (typeof typographyContent === 'string') {
    const stringTypeProps = { ...props, className: styles['typography-paragraph'] }
    return TypographyStringType({
      styleOverride,
      variantOverride,
      typographyContent,
      props: stringTypeProps,
      richText: RICH_TEXT,
    })
  }
  // otherwise must be an object
  const { textContent } = handleContentToMapper(typographyContent)

  const createRenderNode = (
    node,
    children,
    blockType,
    className = 'typography-default',
    variantToChild = false,
    styleToChild = false
  ) => {
    const dataTestId = _get(node, 'content[0].value')
      ? `${_get(node, 'content[0].value')}`.slice(0, 50)
      : 'no-id-needed'
    const dataIdReference = _get(props, 'id')
      ? _get(props, 'id')
      : `${BLOCKS[blockType]}-${(_get(node, 'content[0].value') ?? '')
          .slice(0, 50)
          .replaceAll(' ', '-')}`
    const propsComponent = {
      ['data-testid']: dataTestId,
      fontWeight: RICH_TEXT[BLOCKS[blockType]].fontWeight,
      id: removeSpecialCharacters(dataIdReference),
      ...props,
    }

    return createRenderNodeDefault({
      children,
      blockType,
      variantToChild,
      styleToChild,
      variantOverride,
      styleOverride,
      className,
      richText: RICH_TEXT,
      propsComponent,
    })
  }

  const optionsInit = optionsDefault({
    createRenderNode,
    props,
    styleOverride,
    variantOverride,
    richText: RICH_TEXT,
    stylesNodes: styles,
  })

  const options = {
    renderMark: { ...optionsInit.renderMark },
    renderNode: {
      ...optionsInit.renderNode,
      [BLOCKS.PARAGRAPH]: (node, children) =>
        createRenderNode(node, children, 'PARAGRAPH', styles['typography-paragraph']),
      [BLOCKS.HEADING_1]: (node, children) =>
        createRenderNode(node, children, 'HEADING_1', styles['typography-heading-1'], true),
      [BLOCKS.HEADING_2]: (node, children) =>
        createRenderNode(node, children, 'HEADING_2', styles['typography-heading-2'], true),
      [BLOCKS.HEADING_3]: (node, children) =>
        createRenderNode(node, children, 'HEADING_3', styles['typography-heading-3'], true),
      [BLOCKS.HEADING_4]: (node, children) =>
        createRenderNode(node, children, 'HEADING_4', styles['typography-heading-4'], true),
      [BLOCKS.HEADING_5]: (node, children) =>
        createRenderNode(node, children, 'HEADING_5', styles['typography-heading-5'], true),
      [BLOCKS.HEADING_6]: (node, children) =>
        createRenderNode(node, children, 'HEADING_6', styles['typography-heading-6'], true),
      [INLINES.ASSET_HYPERLINK]: (node, children) => {
        const linkId = node?.content[0]?.value
        const url = node?.data?.target?.fields?.file.url
        return (
          <SunbeamLink
            target="_blank"
            sx={selectOverride(INLINES.ASSET_HYPERLINK, styleOverride, 'sx', RICH_TEXT)}
            variant={selectOverride(INLINES.ASSET_HYPERLINK, variantOverride, 'variant', RICH_TEXT)}
            data-testid={`Link-${url}-${linkId}`}
            href={rewriteAssetUrl(url)}
            aria-label={`${children} - link opens in a new tab`}
            {...props}
            component={RICH_TEXT[INLINES.ASSET_HYPERLINK].component}
            className={styles['typography-hyperlink']}
          >
            {children}
          </SunbeamLink>
        )
      },
      [INLINES.EMBEDDED_ENTRY]: (node) => {
        // TODO: Handle other types of inline entries. Currently,
        //       node is a data object with no content. Rendering in
        //       an html tag does not work, invalid React child
        const { plainText, styledText, variation, identifier, videoPlayer, uiComponent } = _get(
          node,
          'data.target.fields',
          {}
        )
        const { contentType } = _get(node, 'data.target.sys', {})
        if (contentType?.sys?.id === 'articleCtaCard') {
          const { fields } = _get(node, 'data.target', {})
          return <CardCTA content={fields} trackingName={'In-Content'} />
        }
        if (styledText === 'superscript') {
          return <SuperScript text={plainText} variation={variation} {...props} />
        }
        if (styledText === 'scrollTo') {
          return <span data-scrolto={identifier}></span>
        }
        if (styledText === 'scrollFrom') {
          return (
            <SunbeamLink
              sx={selectOverride(INLINES.ASSET_HYPERLINK, styleOverride, 'sx', RICH_TEXT)}
              variant={selectOverride(
                INLINES.ASSET_HYPERLINK,
                variantOverride,
                'variant',
                RICH_TEXT
              )}
              data-testid={`Scroll-to-${plainText}`}
              onClick={() => {
                const el = document.querySelector(`[data-scrolto="${identifier}"]`)
                if (el) {
                  // Adding -70px to fix element padding
                  window.scrollTo({
                    top: el.offsetTop - 72,
                    behavior: 'smooth',
                  })
                }
              }}
              aria-label={`Scroll to ${plainText}`}
              {...props}
              component={RICH_TEXT[INLINES.ASSET_HYPERLINK].component}
            >
              {plainText}
            </SunbeamLink>
          )
        }
        if (videoPlayer) {
          const { target } = _get(node, 'data', {})
          return <VideoDialog content={target} />
        }
        if (uiComponent === 'VideoPlayer') {
          const target = _get(node, 'data.target.fields', {})
          return (
            <VideoPlayer
              content={target}
              frameClassName={styles['video-player']}
              frameEmbed={true}
            />
          )
        }
        if (contentType?.sys?.id === 'uncrawlableLink') {
          return (
            <UncrawlableLinkTypography
              node={node}
              styleOverride={styleOverride}
              variantOverride={variantOverride}
            />
          )
        }

        return null
      },
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        // TODO: Handle other types of inline entries. Currently,
        //       node is a data object with no content. Rendering in
        //       an html tag does not work, invalid React child
        const { plainText, styledText, variation, identifier, videoPlayer, uiComponent } = _get(
          node,
          'data.target.fields',
          {}
        )
        const { contentType } = _get(node, 'data.target.sys', {})
        if (contentType?.sys?.id === 'articleCtaCard') {
          const { fields } = _get(node, 'data.target', {})
          return <CardCTA content={fields} trackingName={'In-Content'} />
        }
        if (styledText === 'superscript') {
          return <SuperScript text={plainText} variation={variation} {...props} />
        }
        if (styledText === 'scrollTo') {
          return <span data-scrolto={identifier}></span>
        }
        if (styledText === 'scrollFrom') {
          return (
            <SunbeamLink
              sx={selectOverride(INLINES.ASSET_HYPERLINK, styleOverride, 'sx', RICH_TEXT)}
              variant={selectOverride(
                INLINES.ASSET_HYPERLINK,
                variantOverride,
                'variant',
                RICH_TEXT
              )}
              data-testid={`Scroll-to-${plainText}`}
              onClick={() => {
                const el = document.querySelector(`[data-scrolto="${identifier}"]`)
                if (el) {
                  // Adding -70px to fix element padding
                  window.scrollTo({
                    top: el.offsetTop - 72,
                    behavior: 'smooth',
                  })
                }
              }}
              aria-label={`Scroll to ${plainText}`}
              {...props}
              component={RICH_TEXT[INLINES.ASSET_HYPERLINK].component}
            >
              {plainText}
            </SunbeamLink>
          )
        }
        if (videoPlayer) {
          const { target } = _get(node, 'data', {})
          return <VideoDialog content={target} />
        }
        if (uiComponent === 'VideoPlayer') {
          const target = _get(node, 'data.target.fields', {})
          return (
            <VideoPlayer
              content={target}
              frameClassName={styles['video-player']}
              frameEmbed={true}
            />
          )
        }
        if (contentType?.sys?.id === 'uncrawlableLink') {
          return (
            <UncrawlableLinkTypography
              node={node}
              styleOverride={styleOverride}
              variantOverride={variantOverride}
            />
          )
        }

        return null
      },
    },
    renderText: optionsInit.renderText,
  }

  return documentToReactComponents(textContent, options)
}

export default TypographyLongForm
export { TypographyLongForm, RICH_TEXT }
